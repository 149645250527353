<template>
  <v-container class="subscribes">
    <h1>Мої підписки</h1>
    <div class="d-flex flex-wrap">
      <v-btn
        class="mb-2 mr-4"
        color="success"
        @click="$router.push({ name: 'Land' })"
        >Додати підписку<v-icon>mdi-plus</v-icon></v-btn
      >
      <v-btn class="mb-2" color="error" @click="unsubscribe"
        >Відмінити підписки<v-icon>mdi-delete</v-icon></v-btn
      >
    </div>
    <v-checkbox
      color="success"
      v-model="agricultureLand"
      label="Землі с/г"
      hide-details
    />
    <v-checkbox
      color="success"
      v-model="otherLand"
      label="Всі інші цільові призначення"
      hide-details
    />
    <div class="mt-4">
      <v-btn class="cursor-pointer" @click="dialogClassification = true"
        >Класифікація за кодом КВЗУ
        {{
          selectedClassifications.length
            ? `(${selectedClassifications.length})`
            : ''
        }}</v-btn
      >
    </div>
    <v-row>
      <v-col cols="4">
        <v-text-field
          clearable
          label="Введіть населенний пункт"
          v-model="searchArea"
        />
      </v-col>
    </v-row>
    <p
      class="text-secondary"
      v-if="searchArea && !filteredItems[0].children.length"
    >
      За пошуком "{{ searchArea }}" нічого не знайдено
    </p>
    <section class="subscribes__wrap" v-else-if="filteredItems">
      <v-treeview
        rounded
        hoverable
        open-all
        color="success"
        dense
        :items="filteredItems"
      >
        <template v-slot:label="{ item }">
          <b v-if="item.type" :style="dynamicColor(item.type)">
            {{ item.name }}
          </b>
          <span v-else :style="dynamicColor(item.type)">{{ item.name }}</span>
        </template>
        <template v-slot:append="{ item, leaf }">
          <div class="d-flex" :class="{ area: leaf }">
            <div class="d-flex flex-column column mr-6 pa-1">
              <div class="d-flex flex-column" v-if="item.id === 1">
                <span class="text-center">Аукціони</span>
                <div class="d-flex mt-2">
                  <v-badge
                    class="d-none d-sm-flex ml-2"
                    color="warning"
                    :content="item.amount_sell"
                    overlap
                  >
                    <v-icon size="28" color="success"
                      >mdi-progress-clock</v-icon
                    >
                  </v-badge>
                  <v-badge
                    class="d-none d-sm-flex ml-4"
                    color="warning"
                    :content="item.amount_rent"
                    overlap
                  >
                    <v-icon size="28" color="success">mdi-cart</v-icon>
                  </v-badge>
                </div>
              </div>
              <div
                v-if="item.type === 'district' || item.type === 'settlement'"
                class="d-flex justify-space-around"
              >
                <span :style="dynamicColor(item.type)">{{
                  item.amount_sell
                }}</span>
                <span :style="dynamicColor(item.type)">{{
                  item.amount_rent
                }}</span>
              </div>
              <div
                v-else-if="item.type !== 'region'"
                class="d-flex justify-space-around"
              >
                <v-simple-checkbox
                  color="success"
                  v-ripple
                  v-model="item.sell"
                />
                <v-simple-checkbox
                  color="success"
                  v-ripple
                  v-model="item.rent"
                />
              </div>
            </div>
            <div class="d-flex flex-column column ml-6 pa-1">
              <div class="d-flex flex-column" v-if="item.id === 1">
                <span class="text-center">Статистика</span>
                <div class="d-flex mt-2">
                  <v-badge
                    class="d-none d-sm-flex ml-2"
                    color="warning"
                    :content="item.amount_sell_stat"
                    overlap
                  >
                    <v-icon size="28">mdi-progress-clock</v-icon>
                  </v-badge>
                  <v-badge
                    class="d-none d-sm-flex ml-4"
                    color="warning"
                    :content="item.amount_rent_stat"
                    overlap
                  >
                    <v-icon size="28">mdi-cart</v-icon>
                  </v-badge>
                </div>
              </div>
              <div
                v-else-if="item.type !== 'region'"
                class="d-flex justify-space-around"
              >
                <v-simple-checkbox
                  color="success"
                  v-ripple
                  v-model="item.sell_stat"
                />
                <v-simple-checkbox
                  color="success"
                  v-ripple
                  v-model="item.rent_stat"
                />
              </div>
            </div>
          </div>
        </template>
      </v-treeview>
      <Modal
        width="700"
        :scrollable="true"
        :dialog="dialogClassification"
        @close="dialogClassification = false"
      >
        <template v-slot:title>
          <span>Група (КВЗУ)</span>
        </template>
        <template v-slot:body>
          <v-treeview
            rounded
            hoverable
            selectable
            open-all
            selected-color="success"
            v-model="selectedClassifications"
            dense
            :items="classifications"
          >
          </v-treeview>
        </template>
      </Modal>
    </section>
  </v-container>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import { mapActions } from 'vuex'
import mixinsDynamicStyles from '../mixins'
const Modal = () => import('@/shared/components/Modal')
export default {
  name: 'SubscribesComponent',
  mixins: [mixinsDynamicStyles],
  components: {
    Modal
  },
  data: () => ({
    searchArea: null,
    agricultureLand: false,
    otherLand: false,
    items: [
      {
        id: 1,
        name: 'Харківська',
        type: 'region',
        amount_sell: 9,
        amount_rent: 9,
        amount_sell_stat: 11,
        amount_rent_stat: 15,
        children: [
          {
            id: 2,
            name: "Куп'янський р-н",
            type: 'district',
            amount_sell: 5,
            amount_rent: 3,
            amount_sell_stat: 5,
            amount_rent_stat: 7,
            children: [
              {
                id: 3,
                name: 'Шевченківська',
                type: 'settlement',
                amount_sell: 4,
                amount_rent: 1,
                amount_sell_stat: 3,
                amount_rent_stat: 3,
                children: [
                  {
                    id: 4,
                    name: 'Шевченкове',
                    sell: false,
                    rent: true,
                    sell_stat: true,
                    sell_rent: false
                  },
                  {
                    id: 5,
                    name: 'Аркадівка',
                    sell: false,
                    rent: true,
                    sell_stat: false,
                    sell_rent: true
                  },
                  {
                    id: 6,
                    name: 'Баранове',
                    sell: false,
                    rent: true,
                    sell_stat: false,
                    sell_rent: false
                  },
                  {
                    id: 7,
                    name: 'Безмятежне',
                    sell: false,
                    rent: true,
                    sell_stat: true,
                    sell_rent: false
                  },
                  {
                    id: 8,
                    name: 'Березівка',
                    sell: false,
                    rent: true,
                    sell_stat: false,
                    sell_rent: false
                  }
                ]
              },
              {
                id: 9,
                name: 'Кіндрашівська',
                type: 'settlement',
                amount_sell: 1,
                amount_rent: 2,
                amount_sell_stat: 2,
                amount_rent_stat: 4,
                children: [
                  {
                    id: 10,
                    name: 'Кіндрашівка',
                    sell: false,
                    rent: true,
                    sell_stat: false,
                    sell_rent: true
                  },
                  {
                    id: 11,
                    name: 'Благодатівка',
                    sell: false,
                    rent: true,
                    sell_stat: true,
                    sell_rent: false
                  },
                  {
                    id: 12,
                    name: 'Василівка',
                    sell: false,
                    rent: false,
                    sell_stat: false,
                    sell_rent: false
                  },
                  {
                    id: 13,
                    name: 'Василівка',
                    sell: false,
                    rent: false,
                    sell_stat: false,
                    sell_rent: false
                  }
                ]
              }
            ]
          },
          {
            id: 14,
            name: 'Чугуївський р-н',
            type: 'district',
            amount_sell: 4,
            amount_rent: 6,
            amount_sell_stat: 6,
            amount_rent_stat: 8,
            children: [
              {
                id: 15,
                name: 'Старосалтівська',
                type: 'settlement',
                amount_sell: 1,
                amount_rent: 2,
                amount_sell_stat: 2,
                amount_rent_stat: 4,
                children: [
                  {
                    id: 16,
                    name: 'Старий Салтів',
                    sell: false,
                    rent: true,
                    sell_stat: false,
                    sell_rent: false
                  },
                  {
                    id: 17,
                    name: 'Березники',
                    sell: false,
                    rent: false,
                    sell_stat: false,
                    sell_rent: false
                  },
                  {
                    id: 18,
                    name: 'Вишневе',
                    sell: false,
                    rent: false,
                    sell_stat: false,
                    sell_rent: false
                  },
                  {
                    id: 19,
                    name: 'Гонтарівка',
                    sell: false,
                    rent: false,
                    sell_stat: false,
                    sell_rent: false
                  },
                  {
                    id: 20,
                    name: 'Дідівка',
                    sell: false,
                    rent: false,
                    sell_stat: false,
                    sell_rent: false
                  }
                ]
              },
              {
                id: 21,
                name: 'Печенізька',
                type: 'settlement',
                amount_sell: 1,
                amount_rent: 2,
                amount_sell_stat: 2,
                amount_rent_stat: 4,
                children: [
                  {
                    id: 22,
                    name: 'Печеніги',
                    sell: false,
                    rent: false,
                    sell_stat: false,
                    sell_rent: false
                  },
                  {
                    id: 23,
                    name: 'Артемівка',
                    sell: false,
                    rent: false,
                    sell_stat: false,
                    sell_rent: false
                  },
                  {
                    id: 24,
                    name: 'Борщова',
                    sell: false,
                    rent: false,
                    sell_stat: false,
                    sell_rent: false
                  },
                  {
                    id: 25,
                    name: 'Ганнівка',
                    sell: false,
                    rent: false,
                    sell_stat: false,
                    sell_rent: false
                  },
                  {
                    id: 26,
                    name: 'Гарашківка',
                    sell: false,
                    rent: false,
                    sell_stat: false,
                    sell_rent: false
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    filteredItems: null,
    dialogClassification: false,
    classifications: [
      {
        id: 1,
        name: '001',
        locked: true,
        children: [
          { id: 2, name: '00 Рілля' },
          { id: 3, name: '01 Рілля' },
          { id: 4, name: '02 Перелоги' }
        ]
      },
      {
        id: 5,
        name: '002',
        children: [
          { id: 6, name: '00 Рослинний покрив земель і грунти' },
          { id: 7, name: '01 Сіножаті' },
          { id: 9, name: '02 Пасовщина' },
          { id: 10, name: '03 Багаторічні насадження' }
        ]
      },
      {
        id: 11,
        name: '003',
        children: [
          {
            id: 12,
            name: '00 Землі без рослинного покриву або незначним рослинним покривом'
          },
          { id: 13, name: "01 Кам'янисті місця" },
          { id: 14, name: '02 Піски' },
          { id: 15, name: '03 Болота' },
          { id: 16, name: '04 Солончаки' },
          { id: 17, name: '05 Яри' },
          { id: 18, name: '03 Степи' }
        ]
      },
      {
        id: 19,
        name: '004',
        children: [
          { id: 20, name: '00 Чагарникова рослинність природного походження' }
        ]
      },
      {
        id: 21,
        name: '005',
        children: [
          { id: 22, name: '00 Ліси та інші лісовкриті землі' },
          {
            id: 23,
            name: '01 Земельні лісові ділянки, вкриті лісовою рослинністю'
          },
          {
            id: 24,
            name: '02 Земельні лісові ділянки, не вкриті лісовою рослинністю'
          },
          { id: 25, name: '03 З усіх лісів лісові насадження лінійного типу' },
          { id: 26, name: '04 Інші лісовкриті площі' }
        ]
      }
    ],
    selectedClassifications: []
  }),
  watch: {
    searchArea: {
      handler (val) {
        this.filteredItems = cloneDeep(this.items)
        if (val) {
          this.filteredItems = this.filteredItems.map((region) => {
            region.children = region.children.filter((district) => {
              district.children = district.children.filter((settlement) => {
                settlement.children = settlement.children.filter(
                  (area) => area.name.indexOf(val) !== -1
                )
                return settlement.children.length
              })
              return district.children.length
            })
            return region
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('map', ['removeSubscribes']),
    unsubscribe () {
      this.removeSubscribes()
        .then((res) => {
          console.log(res)
          this.$success({
            text: '<span class="d-flex align-center">Ви успішно відписались<i class="mdi mdi-check font-16 ml-4"></i></span>'
          })
        })
        .catch((e) => {
          console.log(e)
          this.$error({
            text: '<span class="d-flex align-center">Відбулася помилка<i class="mdi mdi-error font-16 ml-4"></i></span>'
          })
        })
    }
  },
  mounted () {
    setTimeout(() => {
      const areas = document.querySelectorAll('.area')
      areas.forEach((val, idx) => {
        if (!(idx % 2)) {
          val.parentElement.parentElement.parentElement.style.background =
            '#e1f8eb'
        }
      })
    }, 200)
  }
}
</script>

<style lang="scss">
.subscribes {
  overflow: auto;
  &__wrap {
    width: 800px;
    @media (max-width: 599px) {
      width: 470px;
    }
  }
  .column {
    width: 90px;
  }
}
</style>
